<template>
  <div
    v-if="sortOptions"
    class="mx-2 d-flex"
    :style="sortStyle"
  >
    <v-select
      outlined
      hide-details
      dense
      :label="label"
      :items="sortOptions"
      v-model="sortKey"
    >
      <template v-slot:append-outer>
        <v-btn icon small @click="toggleSortType">
          <v-icon v-if="sortType === 'asc'">fa fa-long-arrow-alt-up</v-icon>
          <v-icon v-if="sortType === 'desc'">fa fa-long-arrow-alt-down</v-icon>
        </v-btn>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  methods: {
    toggleSortType() {
      let newType = this.sortType === 'asc' ? 'desc' : 'asc'
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: ['sort', 'type'],
        value: newType,
      })

      // 更換排序類型後從第一頁開始
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'page',
        value: 1,
      })

      this.listActions.changeIndexCondition()
    },
  },
  computed: {
    sortStyle() {
      let width = '180px'
      if(this.listConfig.sortContainerWidth) {
        width = this.listConfig.sortContainerWidth
      }
      return {
        width,
      }
    },
    label() {
      const sortTypeLabel = this.$t(`action.sort_${this.sortType}`)
      return `${this.$t('action.sort_key')} (${sortTypeLabel})`
    },
    sortOptions() {
      const sortOptions = this.$store.getters[`list/${this.listKey}/sortOptions`]
      if(!sortOptions) return null
      return sortOptions.map(option => ({
        text: this.$t(option.label),
        value: option.key,
      }))
    },
    sortType() {
      return this.$store.getters[`list/${this.listKey}/sortType`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    sortKey: {
      get() {
        return this.$store.getters[`list/${this.listKey}/sortKey`]
      },
      set(value) {
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: ['sort', 'key'],
          value,
        })

        // 更換排序依據後, 排序類型直接改成遞減
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: ['sort', 'type'],
          value: 'desc',
        })

        // 更換排序依據後從第一頁開始
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: 'page',
          value: 1,
        })

        this.listActions.changeIndexCondition()
      },
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
  }
}
</script>

<style lang="sass" type="text/sass" scoped></style>